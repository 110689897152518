import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";

const BreadCrumbs = (rv) => {
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Typography color="text.primary">{rv ? "Response Validation" : "Simulations"}</Typography>
      <Typography color="text.primary">{rv ? "Exercise Library" : "Simulation Library"}</Typography>
    </MUIBreadcrumbs>
  );
};

export default BreadCrumbs;
