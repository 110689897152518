import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttpRequest } from "../../../Hooks";
import { useAppSelector } from "../../../Redux/app/hooks";
import RVExecutionDetails from "./Components/RVExecutionDetails";
import RVExerciseDetails from "./Components/RVExerciseDetails";
import RVExpectedResponse from "./Components/RVExpectedResponse";
import RVResponseTeamValidated from "./Components/RVResponseTeamValidated";
import RVStatus from "./Components/RVStatus";

const RVCampaignDetails = () => {
  const { executedUUID } = useParams();
  const current_customer = useAppSelector((state) => state.customer);
  const [reload, setReload] = useState(false);
  const {
    response,
    loading,
    fetch: getCampaign,
  } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/responsevalidation/campaign/${executedUUID}`,
  });

  useEffect(() => {
    getCampaign();
  }, [reload, getCampaign]);

  if (loading) {
    return <CircularProgress />;
  }

  const campaign = response?.campaign;

  return (
    <Box p={2}>
      {/* Breadcrumbs */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
          <Typography color="text.primary">Response Validation</Typography>
          <Typography color="text.primary">Campaign</Typography>
          <Typography color="text.primary">{campaign?.name}</Typography>
        </Breadcrumbs>
      </Stack>
      <Typography variant="h4" fontWeight={"bold"} sx={{ mb: 1 }}>
        Threat Response Exercise
        <Typography variant="h5" fontWeight={"bold"} component={"span"} sx={{ ml: 4 }}>
          {campaign?.start
            ? moment(campaign.start).format("MMMM D, YYYY")
            : "N/A"}
        </Typography>
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <RVExerciseDetails campaign={campaign} />
          <RVExecutionDetails campaign={campaign} />
          <RVResponseTeamValidated campaign={campaign} />
          <RVStatus passed={response?.passed} />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <RVExpectedResponse campaign={campaign} setReload={setReload} />
        </Stack>
      </Box>
    </Box>
  );
};

export default RVCampaignDetails;
