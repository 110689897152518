import {
  Box,
  CircularProgress,
  Stack,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { FaRedo } from "react-icons/fa";
import { TextButton } from "../../../../Components";
import { AVDetailsContext } from "../ContextProvider/ContextProvider";
import { useReprocessAlerts } from "../lib/hooks";
import { DownloadAlertJson } from "./DownloadAlertJson";
import { DownloadAlertReport } from "./DownloadAlertReport";

const PageTitle = () => {
  const { alertProcessing, pageTitle, campaignUUID } =
    useContext(AVDetailsContext);
  const { reprocessAlerts } = useReprocessAlerts();

  return (
    <Box sx={{ width: "100%" }}>
      {/* Page Title Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          {pageTitle}
        </Typography>
        <Stack direction={"row"}>
          <DownloadAlertJson uuid={campaignUUID} name={pageTitle} />
          <DownloadAlertReport uuid={campaignUUID} name={pageTitle} />
          <TextButton
            tooltip="Reprocess Results"
            icon={alertProcessing ? CircularProgress : FaRedo}
            loading={alertProcessing}
            onClick={reprocessAlerts}
          />
        </Stack>
      </Box>
      
    </Box>
  );
};

export default PageTitle;
