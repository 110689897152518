import React, { ReactNode, useState } from "react";
import { AvOverviewContext } from "./Context";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";
import { useAppSelector } from "../../../../Redux/app/hooks";
import Loading from "./Loading";

// If useGetRequest has a defined return type, replace 'any' with that type
type UseGetResponseType = any;

// Define a type for the Provider's props
interface ProviderProps {
  children: ReactNode;
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [avgMTTD, setAvgMTTD] = useState<number>(0);
  const [avgPassRate, setAvgPassRate] = useState<number>(0);
  const [passedCount, setPassedCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [avgRemidateTime, setAvgRemidateTime] = useState<number>(0);
  const customer = useAppSelector((state) => state.customer);
  const response: UseGetResponseType = useGetRequest(
    `/react/api/${customer.uuid}/alertvalidation/alert-history/all`
  );

  // Provider values
  const values = {
    data: response,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    avgMTTD,
    setAvgMTTD,
    avgPassRate,
    setAvgPassRate,
    passedCount,
    setPassedCount,
    failedCount,
    setFailedCount,
    avgRemidateTime,
    setAvgRemidateTime,
  };

  if (response.status !== 200) {
    return <Loading />;
  }

  return (
      response.status === 200 && (
          <AvOverviewContext.Provider value={values}>
            {children}
          </AvOverviewContext.Provider>
      )
  );
};

export default Provider;