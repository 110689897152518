import React, { ReactNode, useEffect, useState } from "react";
import { RvOverviewContext } from "./Context";
import { useAppSelector } from "../../../../Redux/app/hooks";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";

// Define a type for the Provider's props
interface ProviderProps {
  children: ReactNode;
}

type UseGetResponseType = any;

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const customer = useAppSelector((state) => state.customer);
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const response: UseGetResponseType = useGetRequest(
    `/api/v2/${customer.uuid}/response-providers`
  );

   // Update selected vendors whenever vendors list updates
   useEffect(() => {
    if (response.status === 200) {
      setSelectedVendors(response.data.map((vendor) => vendor.id));
    }
  }, [response]);

  // Provider values
  const values = {
    vendors: response.data,
    selectedVendors,
    setSelectedVendors,
  };

  return (
    response.status === 200 && (
      <RvOverviewContext.Provider value={values}>
        {children}
      </RvOverviewContext.Provider>
    )
  );
};

export default Provider;
