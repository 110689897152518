import { CircularProgress, Paper, Stack, Typography, Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import { RvOverviewContext } from "../Provider/Context";

const RVComposedChart = () => {
  const { selectedVendors } = useContext(RvOverviewContext);
  const current_customer = useAppSelector((state) => state.customer);
  const { response, loading } = useHttpRequest({
    path: `/react/api/${current_customer.uuid}/responsevalidation/overview/graph`,
    method: "GET",
  });

  const [chartsData, setChartsData] = useState([]);

  useEffect(() => {
    if (response && response.providers) {
      // Process the new grouped data structure
      const formattedChartsData = response.providers.map((provider) => {
        const chartData = provider.campaigns.map((campaign, index) => ({
          name: `C${index + 1}`, // Simplified campaign name for the chart
          tooltipName: campaign.campaign_name,
          link: campaign.link, // Add link if provided
          provider: provider.response_time, // Provider's response time
          campaign: campaign.response_time, // Campaign's response time
        }));
        return {
          provider_id: provider.id, // Add provider ID for filtering
          provider_name: provider.provider_name,
          chartData,
          provider_response_time: provider.response_time, // Reference line
        };
      });

      setChartsData(formattedChartsData);
    }
  }, [response]);

  // **Filter chart data based on selected vendors**
  const filteredChartsData = chartsData.filter((chart) =>
    selectedVendors.includes(chart.provider_id)
  );

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <Typography align="center">
          <CircularProgress />
        </Typography>
      ) : (
        <Stack
          direction="row"
          flexWrap="wrap"
          spacing={2}
          justifyContent="center"
        >
          {filteredChartsData.map((chart, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                flex: "1 1 calc(33.33% - 20px)", // Ensure 3 per row
                margin: "10px",
                padding: "10px",
                boxSizing: "border-box",
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                {`${
                  chart.provider_name.charAt(0).toUpperCase() +
                  chart.provider_name.slice(1)
                } Response Time Chart`}
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={chart.chartData}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis
                    dataKey="name"
                    label={{
                      value: "Campaigns",
                      position: "insideBottom",
                      offset: -5,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Response Time (M)",
                      angle: -90,
                      position: "insideCenter",
                    }}
                    domain={[
                      0,
                      Math.max(
                        ...chart.chartData.map((d) =>
                          Math.max(d.provider, d.campaign)
                        )
                      ) + 1,
                    ]}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "transparent" }}
                  />
                  <Legend
                    verticalAlign="bottom"
                    wrapperStyle={{ lineHeight: "50px" }}
                    payload={[
                      {
                        value: "Campaign Response Time",
                        type: "square",
                        color: "#8a1dc4",
                      },
                      {
                        value: "Provider Response Time",
                        type: "line",
                        color: "#34ebcf",
                      },
                    ]}
                  />

                  {/* Area for campaign response time */}
                  <Area
                    type="monotone"
                    dataKey="campaign"
                    fill={COLORS.secondary.light}
                    stroke="#8884d8"
                    name="Campaign Response Time"
                    legendType="none"
                  />

                  {/* Bar for campaign response time */}
                  <Bar
                    dataKey="campaign"
                    barSize={20}
                    fill="#8a1dc4"
                    name="Campaign Response Time (Bar)"
                    onClick={(data) => {
                      const link = data?.link;
                      if (link) {
                        window.open(link, "_blank"); // Open link in a new tab
                      }
                    }}
                  />

                  {/* Reference line for provider response time */}
                  <ReferenceLine
                    name="Provider Response Time"
                    y={chart.provider_response_time}
                    stroke="#34ebcf"
                    strokeWidth={5}
                    strokeDasharray="6 6"
                    label={{
                      value: "Provider Response Time",
                      position: "right",
                      fill: "#34ebcf",
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Paper>
          ))}
        </Stack>
      )}
    </div>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Filter payload to ensure the tooltip displays correctly
    const filteredPayload = payload.filter(
      (item) => item.name !== "Campaign Response Time"
    );

    return (
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid #ddd",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: 2,
        }}
      >
        {/* Display Tooltip Name */}
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          {filteredPayload[0]?.payload.tooltipName}
        </Typography>
        <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
          Provider Response Time: {filteredPayload[0]?.payload.provider}
        </Typography>

        {/* Display Remaining Payload Values */}
        {filteredPayload.map((item, index) => (
          <Typography key={index} variant="body2" style={{ color: item.color }}>
            {item.name}: {item.value}
          </Typography>
        ))}
        <Typography variant="body3" gutterBottom>
          Click Bar to navigate to campaign
        </Typography>
      </Box>
    );
  }

  return null;
};

export default RVComposedChart;
