import { Route, Routes } from "react-router-dom";
import RVOverview from "../../Pages/ResponseValidation/RVOverview/RVOverview";
import ResponseProviders from "../../Pages/Config/ResponseProviders/ResponseProviders";
import SimLibrary from "../../Pages/Simulations/SimLibrary/SimLibrary";
import EndpointsList from "../../Pages/Simulations/Endpoints/EndpointsList";
import CampaignsList from "../../Pages/Simulations/Campaigns/CampaignsList";
import RVCampaignDetails from "../../Pages/ResponseValidation/RVCampaign/RVCampaignDetails";

const ResponseValidationRoutes = () => {
  return (
    <Routes>
      <Route path="overview" element={<RVOverview />} />
      <Route
        exact
        path="response-teams"
        element={<ResponseProviders main={true} />}
      />
      <Route exact path="library" element={<SimLibrary rv={true} />} />
      <Route exact path="/campaigns/list" element={<CampaignsList rv={true} />} />
      <Route exact path="endpoints/list" element={<EndpointsList rv={true} />} />
      <Route exact path="/campaign/:executedUUID" element={<RVCampaignDetails />}
      />
    </Routes>
  );
};

export default ResponseValidationRoutes;
