import { Breadcrumbs, Stack, Typography, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText } from "@mui/material";
import { useContext } from "react";
import { RvOverviewContext } from "../Provider/Context";

const RVBreadcrumbs = () => {
  const { vendors, selectedVendors, setSelectedVendors } = useContext(RvOverviewContext);

  // Ensure vendors is always an array
  if (!Array.isArray(vendors) || vendors.length === 0) {
    console.error("Vendors is not an array", vendors);
    return null;
  }

  // Handle selection logic
  const handleChange = (event) => {
    const value = event.target.value;

    // Prevent unselecting all items (keep at least one selected)
    if (value.length === 0) return;

    setSelectedVendors(value);
  };

  // Determine if all vendors are selected
  const allSelected = selectedVendors.length === vendors.length;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Response Validation</Typography>
        <Typography color="text.primary">Overview</Typography>
      </Breadcrumbs>

      {/* Vendor Multi-Select */}
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="vendor-multi-select-label">Select Response Team</InputLabel>
        <Select
          labelId="vendor-multi-select-label"
          multiple
          value={selectedVendors}
          onChange={handleChange}
          input={<OutlinedInput label="Select Response Team" />}
          renderValue={(selected) =>
            allSelected ? "All" : selected.map((id) => vendors.find((vendor) => vendor.id === id)?.provider_name || id).join(", ")
          }
        >
          {/* Dynamically render vendor list */}
          {vendors.map((vendor) => (
            <MenuItem key={vendor.id} value={vendor.id}>
              <Checkbox checked={selectedVendors.includes(vendor.id)} />
              <ListItemText primary={vendor.provider_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default RVBreadcrumbs;
