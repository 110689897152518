import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useHttpRequest } from "../../../Hooks";

const EditProviderModal = ({ open, onClose, provider }) => {
  const [updatedProvider, setUpdatedProvider] = useState({
    category: "",
    provider_name: "",
    notification_time: "",
  });

  useEffect(() => {
    if (provider) {
      setUpdatedProvider({
        category: provider.category || "",
        provider_name: provider.provider_name || "",
        notification_time: provider.notification_time || "",
      });
    }
  }, [provider]);

  const { fetch: editProvider } = useHttpRequest({
    path: `/api/v2/response-providers/${provider?.id}`,
    method: "POST",
    defer: true,
    data: updatedProvider,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProvider((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await editProvider();
      onClose();
    } catch (error) {
      console.error("Failed to update provider:", error);
    }
  };

  // Form validation: Ensure required fields are filled
  const isFormValid =
    updatedProvider.provider_name &&
    updatedProvider.category &&
    updatedProvider.notification_time;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Edit Response Team
        </Typography>

        <TextField
          fullWidth
          label="Provider Name"
          name="provider_name"
          margin="normal"
          value={updatedProvider.provider_name}
          onChange={handleInputChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            name="category"
            value={updatedProvider.category}
            onChange={handleInputChange}
          >
            <MenuItem value="MSSP/MDR">MSSP/MDR</MenuItem>
            <MenuItem value="NDR">NDR</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Notification Time"
          name="notification_time"
          margin="normal"
          type="number"
          value={updatedProvider.notification_time}
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSave}
          disabled={!isFormValid}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EditProviderModal;
