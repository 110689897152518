import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { DataGridWithStyles, TextButton } from "../../../Components";
import { useHttpRequest } from "../../../Hooks";
import { COLORS } from "../../../Styles/colors";
import AddProviderModal from "./AddProviderModal";
import EditProviderModal from "./EditProviderModal";

const ResponseProviders = ({ main }) => {
  const { response, loading, fetch } = useHttpRequest({
    method: "GET",
    path: "/api/v2/response-providers",
  });

  const { fetch: deleteProvider } = useHttpRequest({
    method: "DELETE",
    path: `/api/v2/response-providers/`,
    defer: true,
  });

  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (response) {
      setProviders(response);
    }
  }, [response]);

  const handleOpenAddModal = () => setIsAddModalOpen(true);
  const handleOpenEditModal = (provider) => {
    setSelectedProvider(provider);
    setIsEditModalOpen(true);
  };

  const handleCloseModals = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setSelectedProvider(null);
    fetch();
  };

  const columns = [
    { field: "id", headerName: "id", flex: 1, hide: true },
    { field: "provider_name", headerName: "Team Name", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "notification_time",
      headerName: "Notification Time (Mins)",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={3}>
          <Tooltip title="Edit Response Team">
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => handleOpenEditModal(params.row)}
            >
              <FaPencilAlt />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Response Team">
            <IconButton
              variant="outlined"
              color="primary"
              onClick={async () => {
                await deleteProvider(params.id);
                fetch();
              }}
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <Box width="100%">
      {main && (
        <>
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link component={RouterLink} to="/" underline="hover">
              Home
            </Link>
            <Typography color="text.primary">Response Teams</Typography>
          </Breadcrumbs>
          {/* Page Title */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "1rem",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Response Teams</Typography>
            <TextButton
              tooltip="Add Response Team"
              icon={FaPlus}
              onClick={handleOpenAddModal}
            />
          </Box>
        </>
      )}

      {/* DataGrid */}
      <Box>
        <DataGridWithStyles
          name="Response Teams"
          rows={providers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          loading={loading}
          getRowId={(row) => row.id}
          autoHeight
        />
      </Box>

      {/* Modals */}
      <AddProviderModal open={isAddModalOpen} onClose={handleCloseModals} />
      <EditProviderModal
        open={isEditModalOpen}
        onClose={handleCloseModals}
        provider={selectedProvider}
      />
    </Box>
  );
};

export default ResponseProviders;
