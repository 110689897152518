import { Divider, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";

const RVExecutionDetails = ({ campaign }) => {
  // const current_customer = useAppSelector((state) => state.customer);
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2, width: "30%" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
        Execution Details
      </Typography>
      <Divider sx={{mb: 1}}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bold" }}>Execution Status:</Typography>
          <Typography>{campaign?.status || "N/A"}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bold" }}>Process ID:</Typography>
          <Typography>{campaign?.pid || "N/A"}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bold" }}>Start Time:</Typography>
          <Typography>
            {campaign?.start
              ? moment(campaign.start).format("MMMM D, YYYY h:mm a")
              : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bold" }}>Execution Date:</Typography>
          <Typography>
            {campaign?.start
              ? moment(campaign.start).format("MMMM D, YYYY")
              : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontWeight: "bold" }}>End Time:</Typography>
          <Typography>
            {campaign?.end
              ? moment(campaign.end).format("MMMM D, YYYY h:mm a")
              : "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RVExecutionDetails;
