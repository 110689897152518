import { Button, List, ListItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataGridWithStyles } from "../../../Components";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import SSOEditForm from "./components/SSOEditForm";

const SSOConfigurations = () => {
  const { accessToken } = useContext(DataContext);
  const [connections, setConnections] = useState([]);
  const [dataTableRows, setDataTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const populateDataGrid = (connections) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          minWidth: 250,
          flex: 1,
        },
        {
          field: "display_name",
          headerName: "Display Name",
          minWidth: 250,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          flex: 1,
          renderCell: (params) => {
            const handleClick = () => {
              // open edit form with the connection id
              console.log(params.row);
              setSelected(params.row);
              setOpen(true);
            };
            return (
              <Button
                disabled={params.row.strategy !== "waad"}
                onClick={handleClick}
              >
                Edit
              </Button>
            );
          },
        },
      ];

      const rows = connections.map((conn) => {
        return {
          id: conn.id,
          name: conn.name,
          display_name: conn.display_name,
          strategy: conn.strategy,
          secret: conn.options.client_secret,
          options: conn.options,
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };
    const getAlerts = async () => {
      const res = await getRequest("/react/api/admin/sso", accessToken);
      // strategy waad is windows active directory or something similar
      populateDataGrid(res.data);
    };

    getAlerts();
  }, [accessToken]);

  return (
    <>
      <div>SSOConfigurations </div>
      <DataGridWithStyles
        name="admin-customers"
        autoHeight
        pointer
        paper
        rows={dataTableRows}
        columns={dataTableColumns}
      />
      <SSOEditForm open={open} setOpen={setOpen} selected={selected} />
    </>
  );
};

export default SSOConfigurations;
