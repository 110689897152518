import { Paper, Typography, Box, Link, Divider } from "@mui/material";
import { useAppSelector } from "../../../../Redux/app/hooks";

const RVExerciseDetails = ({ campaign }) => {
    const current_customer = useAppSelector((state) => state.customer);
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2, minWidth: "20%" }}>
      <Typography sx={{fontWeight: "bold"}} variant="h6" gutterBottom>
        Exercise Details
      </Typography>
      <Divider sx={{mb: 1}}/>
      <Box>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Exercise - </span>
          <Typography variant="h7" component="span">{campaign?.name || "N/A"}</Typography>
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Endpoint - </span>
          <Link
            href={`/${current_customer}/responsevalidation/endpoints/list`}
            underline="hover"
            variant="h7"
          >
            {campaign?.endpoint?.name || "N/A"}
          </Link>
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Attack Simulation - </span>
          <Link
            href={`/${current_customer}/responsevalidation/library`}
            underline="hover"
            variant="h7"
          >
            {campaign?.simulation?.name || "N/A"}
          </Link>
        </Typography>
        <Typography>
          <span style={{ fontWeight: "bold" }}>Response Providers - </span>
          <Typography variant="h7" component="span">
            {campaign?.response_provider?.provider_name || "N/A"}
          </Typography>
        </Typography>
      </Box>
    </Paper>
  );
};

export default RVExerciseDetails;
