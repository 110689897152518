import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useHttpRequest } from "../../../Hooks";

const AddProviderModal = ({ open, onClose }) => {
  const [newProvider, setNewProvider] = useState({
    category: "",
    provider_name: "",
    notification_time: "",
  });

  const { fetch: addProvider } = useHttpRequest({
    path: "/api/v2/response-providers",
    method: "POST",
    defer: true,
    data: newProvider,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProvider((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    await addProvider();
    setNewProvider({
      category: "",
      provider_name: "",
      notification_time: "",
    });
    onClose();
  };

  // Form validation: Check required fields
  const isFormValid =
    newProvider.provider_name &&
    newProvider.category &&
    newProvider.notification_time;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add Response Team
        </Typography>

        <TextField
          fullWidth
          label="Provider Name"
          name="provider_name"
          margin="normal"
          value={newProvider.provider_name}
          onChange={handleInputChange}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            name="category"
            value={newProvider.category}
            onChange={handleInputChange}
            required
          >
            <MenuItem value="MSSP/MDR">MSSP/MDR</MenuItem>
            <MenuItem value="NDR">NDR</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Notification Time"
          name="notification_time"
          margin="normal"
          type="number"
          value={newProvider.notification_time}
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSave}
          disabled={!isFormValid}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddProviderModal;
