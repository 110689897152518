import { Stack } from "@mui/material";
import RVBreadcrumbs from "./Components/Breadcrumbs";
import RVComposedChart from "./Components/RVComposedGraph";
import RVStatRow from "./Components/RVStatRow";
import MTTRByVendor from "./Components/MTTRByVendor";
import Provider from "./Provider/Provider";

const AVOverview = () => {
  return (
    <Provider>
      <Stack spacing={3}>
        <RVBreadcrumbs />
        <RVStatRow />
        <RVComposedChart />
        <MTTRByVendor />
      </Stack>
    </Provider>
  );
};

export default AVOverview;
