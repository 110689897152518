import {
  CircularProgress,
  Stack,
  Typography,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import RVPassFail from "./RVPassFail";
import { RvOverviewContext } from "../Provider/Context";

const RVStatRow = () => {
  const { selectedVendors } = useContext(RvOverviewContext);
  console.log(selectedVendors)
  const current_customer = useAppSelector((state) => state.customer);
  const { response, loading, fetch: getData } = useHttpRequest({
    path: `/react/api/${current_customer.uuid}/responsevalidation/overview/statrow`,
    method: "GET",
    params: { vendors: selectedVendors.join(",") }
});

  const [mttr, setMTTR] = useState(null);
  const [mttr_rem, setMTTR_Rem] = useState(null);
  const [campaignCount, setCampaignCount] = useState(null);
  const [passedCount, setPassedCount] = useState(1);
  const [failedCount, setFailedCount] = useState(1);

  useEffect(() => {
    if (response && response.mttr !== undefined) {
      setMTTR(response.mttr);
      setMTTR_Rem(response.mttr_rem);
      setCampaignCount(response.campaigns);
      setPassedCount(response.passed_count);
      setFailedCount(response.failed_count);
    }
  }, [response]);

  useEffect(() => {
    getData()
  }, [selectedVendors]);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <Typography align="center">
          <CircularProgress />
        </Typography>
      ) : (
        <Stack
          spacing={2}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="stretch" // Ensure items stretch to fill height
          sx={{ height: "100%" }} // Set the Stack to take full height
        >
          <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
            <Stack spacing={2} alignItems="center" height="100%">
              <Box>
                <Typography flex={1} variant={"h5"}>
                  Scheduled Exercises with Respond Times
                </Typography>
              </Box>
              <Box flex={1} alignContent="center">
                <Typography
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: "lightgray",
                  }}
                  fontSize={32}
                >
                  {campaignCount} Exercises
                </Typography>
              </Box>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
            <RVPassFail passedCount={passedCount} failedCount={failedCount} />
          </Paper>
          <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
            <Stack spacing={2} alignItems="center" height="100%">
              <Box>
                <Typography flex={1} variant={"h5"}>
                  Mean Time To Respond
                </Typography>
              </Box>
              <Box flex={1} alignContent="center">
                <Typography
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: "lightgray",
                  }}
                  fontSize={32}
                >
                  {convertMinutesToTime(mttr)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2, flex: 1 }} variant="outlined">
            <Stack spacing={2} alignItems="center" height="100%">
              <Box>
                <Typography flex={1} variant={"h5"}>
                  Mean Time To Remediate
                </Typography>
              </Box>
              <Box flex={1} alignContent="center">
                <Typography
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: "lightgray",
                  }}
                  fontSize={32}
                >
                  {convertMinutesToTime(mttr_rem)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      )}
    </div>
  );
};

const convertMinutesToTime = (minutes) => {
  if (typeof minutes !== "number" || isNaN(minutes)) return "N/A";

  const wholeMinutes = Math.floor(minutes);
  const seconds = Math.round((minutes % 1) * 60);

  return seconds > 0 ? `${wholeMinutes} mins ${seconds} secs` : `${wholeMinutes} mins`;
};

export default RVStatRow;
