import { useContext, useEffect, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { FormModal } from "../../../../Components";
import { options } from "@amcharts/amcharts4/core";
import { DataContext } from "../../../../Context/dataContext";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";

const SSOEditForm = ({ open, setOpen, selected }) => {
  const { accessToken } = useContext(DataContext);
  const [formData, setFormData] = useState({
    name: "",
    display_name: "",
    client_secret: "",
  });

  useEffect(() => {
    setFormData({
      name: selected?.name,
      display_name: selected?.display_name,
      client_secret: selected?.secret,
    });
  }, [selected]);

  const current_customer = useAppSelector((state) => state.customer);
  const handleSubmit = async () => {
    const data = {
      id: selected.id,
      name: selected.name,
      options: {
        ...selected.options,
        client_secret: formData.client_secret,
      },
    };
    const res = await postRequest(
      "/react/api/admin/update_sso",
      accessToken,
      data,
      true
    );
    console.log(data);
    setOpen(false);
  };
  return (
    <FormModal open={open} setOpen={setOpen}>
      <Stack spacing={2}>
        <Typography variant="h6">Edit SSO Configuration</Typography>
        <TextField
          label="Name"
          value={formData.name}
          disabled
          //onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <TextField
          label="Display Name"
          value={formData.display_name}
          disabled
          //onChange={(e) => setFormData({ ...formData, display_name: e.target.value })}
        />
        <TextField
          label="Client Secret"
          value={formData.client_secret}
          multiline={true}
          onChange={(e) =>
            setFormData({ ...formData, client_secret: e.target.value })
          }
        />
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </Stack>
    </FormModal>
  );
};

export default SSOEditForm;
