import { Alert, Paper, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import CampaignForm from "./CampaignForm";
import CategoryTab from "./tabs/CategoryTab";
import SearchField from "./tabs/SearchField";

const TabContainer = ({rv}) => {
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  const [tabValue, setTabValue] = useState(0);
  const [campaignsRan, setCampaignsRan] = useState(null);

  // handle changing tabs
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  function tabProps(index) {
    return {
      id: `category-tab-${index}`,
      "aria-controls": `category-tabpanel-${index}`,
    };
  }

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getLists = async () => {
      // get the list of endpoints and targets
      const usage = await getRequest(`/react/api/${current_customer.uuid}/campaigns_ran`, accessToken);
      if (usage.status === 200) {
        setCampaignsRan(usage.data.campaignsRan);
      }
    };
    getLists();
  }, [accessToken, current_customer]);

  return (
    <>
      {campaignsRan !== null && (
        <Alert sx={{ width: "26.5%" }}>
          {campaignsRan} Campaigns ran for the month
        </Alert>
      )}
      <SearchField />
      <Paper sx={{ alignItems: "center" }}>
        <Tabs variant="scrollable" value={tabValue} onChange={handleTabChange}>
          <Tab label="All" {...tabProps(0)} />
          <Tab label="Access Brokers" {...tabProps(1)} />
          <Tab label="Adversary Emulation" {...tabProps(2)} />
          <Tab label="Ransomware Emulations" {...tabProps(3)} />
          <Tab label="External C2 Testing" {...tabProps(4)} />
          <Tab label="Technique Deep Dives" {...tabProps(5)} />
          <Tab label="Table Top Injects" {...tabProps(6)} />
          <Tab label="Favorites" {...tabProps(7)} />
        </Tabs>
      </Paper>
      <CategoryTab value={tabValue} />
      <CampaignForm customer={current_customer} rv={rv}/>
    </>
  );
};

export default TabContainer;
