import React, { useEffect, useState, useMemo } from "react";
import { useHttpRequest } from "../../../../Hooks";
import { SimLibraryContext } from "./context";

const SimLibraryProvider = ({ children, rv }) => {
  const [simulations, setSimulations] = useState([]);
  const [categories] = useState([
    "All",
    "Access Brokers",
    "Adversary Emulations",
    "Ransomware Emulations",
    "External C2 Testing",
    "Technique Deep Dives",
    "Table Top Injects",
    "Favorites",
  ]);
  const [favorites, setFavorites] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedSim, setSelectedSim] = useState(null);
  const {
    response,
    loading,
    fetch: reloadComponents,
  } = useHttpRequest({
    method: "GET",
    path: "/api/v2/simulations",
    params: {
      depth: 1,
      rv: rv,
    },
  });

  useEffect(() => {
    if (response) {
      setSimulations(response);
    }
  }, [response, setSimulations]);

  const values = useMemo(
    () => ({
      simulations,
      favorites,
      setFavorites,
      searchValue,
      setSearchValue,
      categories,
      loading,
      formOpen,
      setFormOpen,
      selectedSim,
      setSelectedSim,
      reloadComponents,
    }),
    [
      simulations,
      favorites,
      setFavorites,
      searchValue,
      setSearchValue,
      categories,
      loading,
      formOpen,
      setFormOpen,
      selectedSim,
      setSelectedSim,
      reloadComponents,
    ],
  );

  useEffect(() => {
    const favs = localStorage.getItem("favsims");
    if (favs) setFavorites(JSON.parse(favs));
  }, []);

  return (
    <SimLibraryContext.Provider value={values}>
      {children}
    </SimLibraryContext.Provider>
  );
};

export default SimLibraryProvider;
