import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { COLORS } from "../../../../Styles/colors";

const RVPassFail = ({passedCount, failedCount}) => {

  const passedPercent = (
    (passedCount / (passedCount + failedCount)) *
    100
  ).toFixed(2);
  const failedPercent = (
    (failedCount / (passedCount + failedCount)) *
    100
  ).toFixed(2);

  return (
    <Box>
      <Typography variant="h6">Pass/Fail Rate</Typography>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* https://www.npmjs.com/package/react-date-range */}
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.percent}%`,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
              data: [
                {
                  id: 0,
                  value: passedCount,
                  label: "Passed",
                  percent: passedPercent,
                },
                {
                  id: 1,
                  value: failedCount,
                  label: "Failed",
                  percent: failedPercent,
                },
              ],
            },
          ]}
          width={400}
          height={220}
          colors={[COLORS.success.light, COLORS.error.light]}
        />
      </Box>
    </Box>
  );
};

export default RVPassFail;
