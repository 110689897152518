import React from "react";
import { Paper, Typography, Grid, Alert, Divider } from "@mui/material";

const RVResponseTeamValidated = ({ campaign }) => {
  const responseProvider = campaign?.response_provider;

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2, minWidth: "20%" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
        Response Team Validated
      </Typography>
      <Divider sx={{mb: 1}}/>
      <Grid container spacing={2}>
        {responseProvider ? (
          <>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>Category:</Typography>
              <Typography>{responseProvider.category || "N/A"}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>Name:</Typography>
              <Typography>{responseProvider.provider_name || "N/A"}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: "bold" }}>SLA Notification Time:</Typography>
              <Typography>{responseProvider.notification_time || "N/A"}</Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Alert severity="warning">
              No Response Team provided.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default RVResponseTeamValidated;
