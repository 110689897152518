import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Alert,
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { FaDesktop, FaEdit, FaPlus } from "react-icons/fa";
import { TextButton } from "../../../../Components";
import AddResponseTime from "../../../ResponseValidation/RVOverview/Components/AddResponseTime";

const RVExpectedResponse = ({ campaign, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2, width: "100%" }}>
      <Box display="flex" flexDirection={"row"}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
          Expected Responses
        </Typography>
        {campaign?.response_provider && (
          <IconButton sx={{ ml: 3 }} onClick={() => setIsEditing(true)}>
            <FaEdit />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
          p: 1,
          width: "100%",
        }}
      >
        {campaign?.response_provider ? (
          <Box display="flex" gap={2} width="100%">
            {/* Notification Box */}
            <Box
              sx={{
                flex: 1,
                borderColor: "success.main",
                borderRadius: 1,
                p: 2,
              }}
            >
              <Alert
                severity="success"
                style={{ display: "flex", alignItems: "center" }}
              >
                Notification
              </Alert>
            </Box>

            {/* Details Box */}
            <Box
              sx={{
                flex: 3,
                borderRadius: 1,
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="body1" fontWeight="bold" mb={2}>
                Notification Details
              </Typography>

              <Box
                sx={{
                  border: "1px solid",
                  p: 1,
                  width: "80%",
                  mb: 1,
                  bgcolor: "lightblue",
                }}
              >
                <Stack direction="row" spacing={2} justifyContent="center">
                  <FaDesktop color="black" fontSize="32px" />
                  <Typography variant="h5">
                    {campaign?.endpoint?.name || "N/A"}
                  </Typography>
                </Stack>
              </Box>
              <ArrowDownwardIcon />
              <Box
                sx={{
                  border: "1px solid",
                  p: 1,
                  width: "80%",
                  mt: 1,
                  mb: 2,
                  bgcolor: "lightblue",
                }}
              >
                <Typography variant="h5">
                  {campaign.response_provider?.provider_name || "N/A"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 2,
                  mb: 2,
                }}
              >
                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                  Attack Time:
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  {moment(campaign.start).format("MMMM D, YYYY h:mm a")}
                </Typography>

                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                  Response Time:
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  {moment(campaign.start)
                    .add(campaign.response_time, "minutes")
                    .format("MMMM D, YYYY h:mm a")}
                </Typography>

                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                  Time to Response:
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  {campaign.response_time} mins
                </Typography>

                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                  SLA Requirement:
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  {campaign.response_provider?.notification_time || "N/A"}
                </Typography>

                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                  Delta:
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  {campaign.response_provider?.notification_time -
                    campaign.response_time || "N/A"}
                </Typography>
              </Box>

              <Typography mt={2}>↓</Typography>
              <Box
                sx={{
                  border: "1px solid",
                  p: 1,
                  width: "80%",
                  mt: 1,
                  bgcolor:
                    campaign.response_provider?.notification_time -
                      campaign.response_time >=
                    0
                      ? "#4ade80"
                      : "#f87171",
                }}
              >
                <Typography variant="h5">
                  {campaign.response_provider?.notification_time -
                    campaign.response_time >=
                  0
                    ? "Response Team Passed"
                    : "Response Team Failed"}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" gap={2} alignItems="center" width="100%">
            {/* Add Response Time Button */}
            <Alert severity="warning" sx={{ flex: 1 }}>
              No Response Time provided.
            </Alert>
            <TextButton
              tooltip="Add Response Time"
              icon={FaPlus}
              onClick={() => setIsModalOpen(true)}
            />
          </Box>
        )}
      </Box>

      {/* Add/Edit Response Time Modal */}
      <AddResponseTime
        open={isModalOpen || isEditing}
        onClose={() => {
          setReload((reload) => !reload);
          setIsModalOpen(false);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        initialData={
          isEditing
            ? {
                provider_id: campaign.response_provider.id,
                response_time: campaign.response_time,
              }
            : null
        }
        campaignUUID={campaign?.uuid}
        campaign={campaign}
      />
    </Paper>
  );
};

export default RVExpectedResponse;
