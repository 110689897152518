import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";

const AddResponseTime = ({
  open,
  onClose,
  isEditing,
  initialData,
  campaignUUID,
  campaign
}) => {
  const current_customer = useAppSelector((state) => state.customer);
  const [responseData, setResponseData] = useState({
    campaignUUID: campaignUUID,
    provider_id: "",
    response_time: "",
  });

  const { fetch: submitResponseTime } = useHttpRequest({
    path: `/react/api/${current_customer.uuid}/responsevalidation/add_response_time`,
    method: "POST",
    defer: true,
    data: responseData,
  });

  const { response: providers, loading: loadingProviders } = useHttpRequest({
    path: `/api/v2/response-providers`,
    method: "GET",
  });

  useEffect(() => {
    if (open && isEditing && initialData) {
      setResponseData({
        campaignUUID: campaignUUID,
        provider_id: initialData.provider_id,
        response_time: initialData.response_time,
      });
    } else if (open && !isEditing) {
      setResponseData({ campaignUUID: campaignUUID, provider_id: "", response_time: "" });
    }
  }, [open, isEditing, initialData, campaignUUID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResponseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!responseData.provider_id || !responseData.response_time) {
      alert("Please select a provider and enter a valid response time.");
      return;
    }

    await submitResponseTime();
    setResponseData({ provider_id: "", response_time: "" });
    onClose();
  };

  const isFormValid = responseData.provider_id && responseData.response_time;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {isEditing ? "Edit Response Time" : "Add Response Time"}
        </Typography>

        {loadingProviders ? (
          <Typography align="center" sx={{ mt: 2 }}>
            Loading providers...
          </Typography>
        ) : (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="provider-label">Response Team</InputLabel>
              <Select
                labelId="provider-label"
                name="provider_id"
                value={responseData.provider_id}
                onChange={handleInputChange}
                required
              >
                {providers?.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.provider_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Response Time (in minutes)"
              name="response_time"
              margin="normal"
              type="number"
              value={responseData.response_time}
              onChange={handleInputChange}
              required
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleSave}
              disabled={!isFormValid}
            >
              {isEditing ? "Save Changes" : "Submit"}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddResponseTime;
