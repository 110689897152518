import React from "react";
import { Box, LinearProgress } from "@mui/material";

const Loading = () => {
  return (
    // create a fake loading bar to show the user that the page is loading using mui
    <div
      style={{
        width: "100%",
        height: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "75%", textAlign: "center" }}>
        Collecting data and Generating Overview...
        <LinearProgress />
      </Box>
    </div>
  );
};

export default Loading;
