import { List } from "@mui/material";
import { useAppSelector } from "../../../Redux/app/hooks";
import Admin from "./Admin";
import AlertValidation from "./AlertValidation";
import Beta from "./Beta";
import Config from "./Config";
import Reports from "./Reports";
import ServiceProvider from "./ServiceProvider";
import Simulations from "./Simulations";
import ResponseValidation from "./ResponseValidation";

const Navigation = ({ open }) => {
  const user = useAppSelector((state) => state.user);
  const isServiceProvider = user.primary_customer?.service_provider !== null;
  return (
    <List>
      <Simulations open={open} />
      <ResponseValidation open={open}/>
      <AlertValidation open={open} />
      <Reports open={open} />
      <Beta open={open} />
      {user.role !== "View_Only" && <Config open={open} />}
      {user.role === "Provider_Admin" && isServiceProvider && (
        <ServiceProvider open={open} />
      )}
      {user.superuser && <Admin open={open} />}
    </List>
  );
};

export default Navigation;
