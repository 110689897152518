import { List } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import Admin from "../Navigation/Admin";
import Beta from "../Navigation/Beta";
import Config from "../Navigation/Config";
import Reports from "../Navigation/Reports";
import ServiceProvider from "../Navigation/ServiceProvider";
import Simulations from "../Navigation/Simulations";
import ResponseValidation from "../Navigation/ResponseValidation";

const RvOnlyNavigation = ({ open }) => {
  const user = useAppSelector((state) => state.user);
  const isServiceProvider = user.primary_customer.service_provider !== null;
  return (
    <List>
      {/* <Simulations open={open} /> */}
      <ResponseValidation open={open} />
      {/* <Reports open={open} /> */}
      <Beta open={open} />
      {/* {user.role !== "View_Only" && <Config open={open} />}
      {user.role === "Provider_Admin" && isServiceProvider && (
        <ServiceProvider open={open} />
      )} */}
      {user.superuser && <Admin open={open} />}
    </List>
  );
};

export default RvOnlyNavigation;
