import React from "react";
import { BreadCrumbs, TitleAndButtons, TabContainer } from "./components";
import SimLibraryProvider from "./provider/SimLibraryProvider";

const SimLibrary = ({rv}) => {
  return (
    <SimLibraryProvider rv = {rv}>
      <BreadCrumbs rv= {rv} />
      <TitleAndButtons />
      <TabContainer rv = {rv} />
    </SimLibraryProvider >
  );
};

export default SimLibrary;
