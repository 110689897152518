import React from "react";
import { Paper, Stack } from "@mui/material";

const RVStatus = ({ passed }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Stack
        sx={{ width: "100%", height: "100%" }}
        direction="row"
        justifyContent="space-between"
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "6rem",
            textAlign: "end",
            color: passed ? "#16a34a" : "#dc2626",
            border: `2px solid ${passed ? "#16a34a" : "#dc2626"}`,
            padding: "12px 24px",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
          }}
        >
          {passed ? "PASSED" : "FAILED"}
        </div>
      </Stack>
    </Paper>
  );
};

export default RVStatus;
