import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useEffect, useState, useContext } from "react";
import { useHttpRequest } from "../../../../Hooks";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import moment from "moment";
import { RvOverviewContext } from "../Provider/Context";

const MTTRByVendor = () => {
  const { selectedVendors } = useContext(RvOverviewContext);
  const current_customer = useAppSelector((state) => state.customer);
  const { response, loading } = useHttpRequest({
    method: "GET",
    path: `/react/api/${current_customer.uuid}/responsevalidation/overview/mttr_by_vendor`,
  });

  const [vendorsData, setVendorsData] = useState([]);

  useEffect(() => {
    if (response && Array.isArray(response)) {
      setVendorsData(response);
    }
  }, [response]);
  // Filter vendors based on selectedVendors
  const filteredVendors = vendorsData.filter((vendor) => 
    selectedVendors.includes(vendor.id)
  );

  return (
    <Paper sx={{ p: 3, width: "100%" }}>
      <Stack spacing={2}>
        <Typography variant="h5">Mean Time to Respond by Vendor</Typography>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          filteredVendors.map((vendor, idx) => (
            <VendorCard key={idx} vendor={vendor} />
          ))
        )}
      </Stack>
    </Paper>
  );
};

const VendorCard = ({ vendor }) => {
  return (
    <Stack
      className="shadow"
      sx={{
        border: `1px solid ${COLORS.info.light}`,
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        backgroundColor: "white",
      }}
    >
      {/* Header Section: Vendor Name */}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: `rgba(46,169,244,0.25)`,
          borderBottom: `1px solid ${COLORS.info.light}`,
          padding: 1,
        }}
      >
        <Typography variant="h6" sx={{ flex: 1, textAlign: "left" }}>
          {vendor.provider_name.replace(/\b\w/g, (char) => char.toUpperCase())}
        </Typography>
      </Stack>

      {/* Metrics Row */}
      <Stack direction="row" alignItems="center" justifyContent="center">
        {/* Response Times & Failures */}
        <Stack flex={2} direction="row" justifyContent="space-around">
          {[
            {
              label: "Pass/Fail Rate",
            },
            {
              label: "Mean Response Time",
              value: `${convertMinutesToTime(vendor.mean_time_to_respond)}`,
            },
            {
              label: "Longest Response Time",
              value: `${convertMinutesToTime(vendor.longest_response_time)}`,
            },
            {
              label: "Shortest Response Time",
              value: `${convertMinutesToTime(vendor.shortest_response_time)}`,
            },
            {
              label: "Total Failures",
              value: vendor.failed,
            },
            {
              label: "Last Failure Date",
              value:
                vendor.last_failure_date && vendor.last_failure_date !== "N/A"
                  ? moment(vendor.last_failure_date).format(
                      "MMM Do, YYYY hh:mm A"
                    )
                  : "N/A",
            },
          ].map((item, index) => (
            <Stack
              key={index}
              sx={{
                flex: 1,
                justifyContent: "center",
                padding: 2,
                alignItems: "center",
                borderRight:
                  index < 5 ? `1px solid ${COLORS.info.light}` : "none",
                position: "relative",
              }}
            >
              <Typography variant="body1" color="primary">
                {item.label}
              </Typography>

              {/* Render PieChart if Label is "Pass/Fail Rate" */}
              {item.label === "Pass/Fail Rate" ? (
                vendor.passed !== undefined && vendor.failed !== undefined ? (
                  vendor.passed + vendor.failed > 0 ? (
                    <>
                      <PieChart
                        sx={{ marginLeft: 13 }}
                        series={[
                          {
                            arcLabel: (item) => `${item.percent}%`,
                            highlightScope: {
                              faded: "global",
                              highlighted: "item",
                            },
                            faded: {
                              innerRadius: 30,
                              additionalRadius: -30,
                              color: "gray",
                            },
                            data: [
                              {
                                id: 0,
                                value: vendor.passed,
                                label: "Passed",
                                percent: (
                                  (vendor.passed /
                                    (vendor.passed + vendor.failed)) *
                                  100
                                ).toFixed(2),
                              },
                              {
                                id: 1,
                                value: vendor.failed,
                                label: "Failed",
                                percent: (
                                  (vendor.failed /
                                    (vendor.passed + vendor.failed)) *
                                  100
                                ).toFixed(2),
                              },
                            ],
                          },
                        ]}
                        height={100}
                        colors={[COLORS.success.light, COLORS.error.light]}
                        slotProps={{
                          legend: { hidden: true },
                        }}
                      />
                    </>
                  ) : (
                    <Typography variant="body2">No Data</Typography>
                  )
                ) : (
                  <CircularProgress size={30} />
                )
              ) : (
                <Typography variant="h6">{item.value}</Typography>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const convertMinutesToTime = (minutes) => {
  if (typeof minutes !== "number" || isNaN(minutes)) return "N/A";

  const wholeMinutes = Math.floor(minutes);
  const seconds = Math.round((minutes % 1) * 60);

  return seconds > 0 ? `${wholeMinutes} mins ${seconds} secs` : `${wholeMinutes} mins`;
};

export default MTTRByVendor;
