import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Collapse,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaCheckCircle,
  FaCopy,
  FaHourglass,
  FaQuestionCircle,
  FaSpinner,
  FaTimesCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import FormModal from "../../../../Components/Modals/FormModal";
import { DataContext } from "../../../../Context/dataContext";
import {
  fileRequest,
  getRequest,
  postRequest,
} from "../../../../Helpers/httpRequests";
import { COLORS } from "../../../../Styles/colors";
import "./spinner.css";

import { useAppSelector } from "../../../../Redux/app/hooks";
import ScoringExcelModal from "../../Projects/ProjectDetails/ScoringExcelModal";
import CustomCell from "./CustomCell";
import { getExecutionMethod, stringAvatar } from "./helpers";
import UploadLogs from "../../../../Components/Reporting/UploadLogs/UploadLogs";

const Campaigns_List = ({rv}) => {
  const role = useAppSelector((state) => state.user.role);
  const customer = useAppSelector((state) => state.customer);
  const [open, setOpen] = useState(true);
  const [remainingOpen, setRemainingOpen] = useState(true);
  const [dataTableRows, setDataTableRows] = useState([]);
  const { accessToken } = useContext(DataContext);
  const [reloadCampaigns, setReloadCampaigns] = useState(false);
  const [runningAgents, setRunningAgents] = useState([]);
  const [registered, setRegistered] = useState([]);
  const [remainingCampaigns, setRemainingCampaigns] = useState([]);
  const [status, setStatus] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [downloading, setDownloading] = useState(false);
  const isMounted = useRef(true);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelUUID, setExcelUUID] = useState(false);
  const [uploadLogsOpen, setUploadLogsOpen] = useState(false);
  const [selectedCampaignUUID, setSelectedCampaignUUID] = useState("");
  const [securityTools, setSecurityTools] = useState([]);

  //Variables
  const columns = [
    {
      field: "name",
      headerName: "Campaign Name",
      flex: 2,
      renderCell: (params) => {
        let path = `/${customer.uuid}/${rv ? "responsevalidation" : "reporting"}/campaign/` + params.row.id;
        const linkStyle = {
          fontSize: 16,
        };

        if (params.id !== params.value) {
          return (
            <Link style={linkStyle} to={path}>
              {params.row.name}
            </Link>
          );
        } else {
          return <Typography sx={linkStyle}>{params.row.name}</Typography>;
        }
      },
    },
    {
      field: "simulation",
      headerName: "Simulation Info",
      // maxWidth: 200,
      flex: 1.75,
      renderCell: (params) => {
        const simLink = `/${customer.uuid}/simulations/${params.row.simulation_uuid}`;
        const endLink = `/${customer.uuid}/simulations/endpoints/detail/${params.row.endpoint_uuid}`;
        return (
          <Stack sx={{ py: 1 }}>
            <div>
              <Tooltip title="Simulation" placement="left" arrow>
                <Link style={{ fontWeight: "bold" }} to={simLink}>
                  {params.row.simulation_uuid
                    ? params.row.simulation
                    : "archived simulation"}
                </Link>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Endpoint" placement="left" arrow>
                <Link to={endLink}>{params.row.endpoint}</Link>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Execution method" placement="left" arrow>
                <Typography sx={{ fontSize: 12 }}>
                  {params.row.Execution_method}
                </Typography>
              </Tooltip>
            </div>
          </Stack>
        );
      },
    },
    {
      field: "Execution_method",
      headerName: "Execution Method",
      maxWidth: 1500,
      flex: 1,
      hide: true,
    },
    {
      field: "endpoint",
      headerName: "Endpoint",
      maxWidth: 700,
      flex: 1,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1.1,
      renderCell: (params) => {
        let icon,
          message,
          color,
          compClass = "";

        switch (params.row.status) {
          case "completed":
            icon = FaCheckCircle;
            color = COLORS.success.main;
            message = "Completed";
            break;

          case "ready":
            icon = FaHourglass;
            color = COLORS.primary.main;
            message = "Ready for execution";
            break;

          case "retrieved":
            icon = FaHourglass;
            color = COLORS.info.main;
            message = "Retrieved for execution";
            break;

          case "error":
            icon = FaTimesCircle;
            color = COLORS.error.main;
            message = "Failed on execution";
            break;

          case "running":
            icon = FaSpinner;
            color = "orange";
            message = "Running";
            compClass = "spinner";
            break;

          case "partial":
            icon = FaCheckCircle;
            color = COLORS.yellow;
            message = "Partial completion";
            break;
          default:
            icon = FaQuestionCircle;
            color = COLORS.secondary.main;
            message = "Unknown status";
            break;
        }
        return (
          <Chip
            icon={React.createElement(icon, {
              size: 20,
              color: "white",
              className: compClass,
            })}
            label={message}
            variant="outlined"
            sx={{
              color: "white",
              backgroundColor: color,
              borderRadius: "6px",
              width: "100%",
              "& .MuiChip-label": {
                marginTop: "3px",
                width: "100%",
                textAlign: "center",
              },
              display: "flex",
              justifyContent: "space-between",
            }}
          />
        );
        // return (
        //   <>
        //     <Stack direction="row" sx={{ width: "100%" }}>
        //       {React.createElement(icon, {
        //         size: 20,
        //         color: color,
        //         className: compClass,
        //       })}
        //       <Typography sx={{ marginLeft: "8px" }}>{message}</Typography>
        //     </Stack>
        //   </>
        // );
      },
    },
    {
      field: "created_by_name",
      headerName: "Created By",
      hide: true,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      renderCell: (params) => {
        const user = params.row.created_by;
        const name = `${user?.first} ${user?.last}`;
        // get month and day
        const date = moment(params.row.created);
        const monthAndDay = date.format("MMM D");
        const time = date.format("h:mm A");

        const handleCopyClick = () => {
          // Copy the text to the clipboard
          navigator.clipboard.writeText(params.row.created);
        };

        return (
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "100%", justifyContent: "center" }}
          >
            <Tooltip
              arrow
              placement="top"
              title={
                <Stack>
                  <Typography>{name}</Typography>
                </Stack>
              }
            >
              <Avatar {...stringAvatar(name)} />
            </Tooltip>
            <Tooltip
              title={
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div style={{ padding: "0.5rem" }}>{params.row.created}</div>
                  <IconButton onClick={handleCopyClick}>
                    <FaCopy color="white" />
                  </IconButton>
                </Stack>
              }
              placement="top"
              arrow
            >
              <Stack>
                <Typography sx={{ fontSize: 16 }}>{monthAndDay}</Typography>
                <Typography sx={{ fontSize: 12 }}>{time}</Typography>
              </Stack>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "score",
      headerName: "Score",
      // maxWidth: 100,
      flex: 0.75,
      renderCell: (params) => {
        let color = "success";
        if (params.row.score * 100 < 50) {
          color = "error";
        } else if (params.row.score * 100 < 75) {
          color = "warning";
        }
        return (
          <div
            style={{
              position: "relative",
              display: "inline-flex",
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "50%",
            }}
          >
            <CircularProgress
              variant="determinate"
              value={params.row.score * 100}
              size={45}
              thickness={3}
              color={color}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body2">
                {`${Math.round(params.row.score * 100)}%`}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "scoring_complete",
      headerName: "Fully Scored",
      // maxWidth: 100,
      flex: 0.75,
      renderCell: (params) => {
        if (params.row.scoring_complete) {
          return (
            <Stack direction="row" alignItems="center" sx={{ marginLeft: 2 }}>
              <FaCheckCircle size={40} color={COLORS.success.main} />
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" alignItems="center" sx={{ marginLeft: 2 }}>
              <FaTimesCircle size={40} color={COLORS.error.main} />
            </Stack>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "",
      maxWidth: 50,
      flex: 0.5,
      renderCell: (params) => {
        const handleDeleteCampaign = () => {
          setDeleteName(params.row.name);
          setDeleteUUID(params.row.campaign_uuid);
          setDeleteModal(true);
        };

        const handleDownloadCampaign = () => {
          downloadCampaign(params);
        };

        const genScoringExc = () => {
          genScoringExcel(params.row.campaign_uuid, params.row.name);
        };

        const handleUploadExcel = () => {
          setExcelUUID(params.row.campaign_uuid);
          setOpenExcelModal(true);
        };

        const addDefenderScoring = () => {
          setSelectedCampaignUUID(params.row.campaign_uuid);
          setUploadLogsOpen(true);
        };

        const matrixLink = `/${customer.uuid}/reporting/matrix/${params.row.id}`;
        return (
          <CustomCell
            params={params}
            role={role}
            handleDeleteCampaign={handleDeleteCampaign}
            handleDownloadCampaign={handleDownloadCampaign}
            handleUploadExcel={handleUploadExcel}
            genScoringExcel={genScoringExc}
            matrixLink={matrixLink}
            downloading={downloading}
            addDefenderScoring={addDefenderScoring}
            securityTools={securityTools}
          />
        );
      },
    },
  ];

  //Functions

  const genScoringExcel = async (uuid, name) => {
    setDownloading(true);
    toast.loading("Downloading Excel File");
    // Remove spaces from the name and replace with underscores
    const filename = `${name.replace(/\s+/g, "_")}.xlsx`;
    try {
      const res = await fileRequest(
        `/react/api/${customer.uuid}/campaign/scoring_excel/${uuid}`,
        accessToken,
        filename
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Scoring Excel Downloaded Successfully");
      } else {
        toast.dismiss();
        toast.error("Error downloading scoring document");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.dismiss();
      toast.error("An error occurred while downloading the document");
    }
    setDownloading(false);
  };

  // Function used to refresh table
  const handleRefresh = async () => {
    setRefreshing(true);
    const res = await getRequest(
      `/react/api/${customer.uuid}/campaigns`,
      accessToken
    );
    if (isMounted.current) {
      if (res.status === 200) {
        populateDataGrid(res.data.campaigns);
        setRemainingCampaigns(res.data.remaining_campaigns);
        setRefreshing(false);
      }
    }
  };

  const downloadCampaign = async (params) => {
    const res = await fileRequest(
      `/react/api/${customer.uuid}/report/download/${params.row.campaign_uuid}/${params.row.id}`,
      accessToken,
      `${params.row.name}.csv`
    );
    if (res.status === 200) {
      return;
    }
  };

  const deleteCampaign = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${customer.uuid}/campaign/delete/${deleteUUID}`,
        accessToken
      ),
      {
        pending: "Deleting Campaign",
        success: `Campaign Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setDeleteModal(false);
      setReloadCampaigns((current) => !current);
    }
  };

  const populateDataGrid = (campaigns) => {
    const rows = campaigns.map((campaign) => {
      const executionMethod = getExecutionMethod(campaign);
      let id;
      if (campaign.status !== "ready") {
        id = campaign.uuid;
      } else {
        id = campaign.name;
      }
      return {
        id,
        campaign_uuid: campaign.uuid,
        endpoint_uuid: campaign.endpoint.uuid,
        name: campaign.name,
        endpoint: campaign.endpoint && campaign.endpoint.name,
        simulation:
          campaign.simulation && campaign.simulation.name,
        simulation_uuid:
          campaign.simulation && campaign.simulation.uuid,
        Execution_method: executionMethod,
        status: campaign.status,
        created: campaign.created,
        created_by: campaign.created_by,
        created_by_name:
          campaign.created_by?.first + " " + campaign.created_by?.last,
        score: campaign.score,
        scoring_complete: campaign.scoring_complete,
        steps: campaign.steps,
        report: "",
        actions: "",
      };
    });
    setDataTableRows(rows);
  };

  // ----- ON PAGE LOAD -----

  useEffect(() => {
    isMounted.current = true;
    const getEndpoints_Assess = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/campaigns?rv=${rv}`,
        accessToken
      );
      if (isMounted.current) {
        if (res.status === 200) {
          populateDataGrid(res.data.campaigns);
          setRunningAgents(res.data.running_agents);
          setRegistered(res.data.registered);
          setRemainingCampaigns(res.data.remaining_campaigns);
          setStatus(true);
        }
      }
    };

    getEndpoints_Assess();

    return () => {
      isMounted.current = false;
    };
  }, [customer, accessToken, reloadCampaigns, rv]);

  useEffect(() => {
    const getSecurityTools = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/config/securitytools`,
        accessToken
      );
      if (res.status === 200) {
        setSecurityTools(res.data.security_tools);
      }
    };

    getSecurityTools();
  }, [customer, accessToken]);

  if (status === false) {
    return (
      <>
        <Typography align="center">
          <CircularProgress />{" "}
        </Typography>
      </>
    );
  } else {
    return (
      <Stack spacing={1}>
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <Typography color="text.primary">Simulations</Typography>
            <Typography color="text.primary">Campaigns</Typography>
          </Breadcrumbs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: 3,
            }}
          >
            <Typography component={"span"} fontWeight={"bold"} variant="h4">
              Campaigns
            </Typography>
          </Box>
        </Box>
        {remainingCampaigns !== null && remainingCampaigns !== "unlimited" && (
          <Collapse in={remainingOpen}>
            <Alert
              severity={remainingCampaigns < 10 ? "error" : "info"}
              variant="outlined"
              onClose={() => {
                setRemainingOpen(false);
              }}
            >
              <Typography>
                You have {remainingCampaigns} campaigns remaining.{" "}
                {remainingCampaigns < 10 &&
                  "Please contact administration for increase"}
              </Typography>
            </Alert>
          </Collapse>
        )}

        <Collapse in={open}>
          <Alert
            severity="info"
            variant="outlined"
            onClose={() => {
              setOpen(false);
            }}
          >
            {registered < 1 && (
              <Typography sx={{ fontWeight: "bold" }}>
                No agents currently registered.
              </Typography>
            )}

            {runningAgents < 1 && (
              <Typography sx={{ fontWeight: "bold" }}>
                No agents currently running. Please start the BlindSPOT agent on
                at least one system to start!
              </Typography>
            )}

            {runningAgents > 0 && (
              <Typography sx={{ fontWeight: "bold" }}>
                You have live agents reporting to the BlindSPOT platform, ready
                to kick off some simulations!
              </Typography>
            )}
          </Alert>
        </Collapse>
        <DataGridWithStyles
          name="campaigns-list"
          autoHeight
          paper
          rows={dataTableRows}
          getRowHeight={() => "auto"}
          columns={columns}
          excel
          filter
          columnBar
          refreshing={refreshing}
          handleRefresh={handleRefresh}
        />
        {/* Scoring Excel Modal */}
        <Modal
          open={openExcelModal}
          onClose={() => setOpenExcelModal(false)}
          sx={{
            width: "30%",
            height: "30%",
            position: "absolute",
            top: "10%",
            left: "40%",
          }}
        >
          <ScoringExcelModal
            uuid={excelUUID}
            setOpenExcelModal={setOpenExcelModal}
            campaign={true}
            setRefresh={setReloadCampaigns}
          />
        </Modal>

        {/* Delete Modal */}
        <FormModal open={deleteModal} setOpen={setDeleteModal}>
          <Box component="form">
            <Typography
              variant="h6"
              style={{ textAlign: "center", marginBottom: 4 }}
            >
              Are you sure you want to remove Campaign {deleteName} ?
            </Typography>
            <Stack direction="column" spacing={2}>
              <Button onClick={() => deleteCampaign()} variant="contained">
                Yes
              </Button>
            </Stack>
          </Box>
        </FormModal>
        <UploadLogs
          setOpen={setUploadLogsOpen}
          open={uploadLogsOpen}
          campaigns={dataTableRows}
          selectedCampaignUUID={selectedCampaignUUID}
        />
      </Stack>
    );
  }
};

export default Campaigns_List;
